$futura-fonts-path: '' !default;
@font-face {
	font-family: 'futura-book';
	font-style: normal;
	font-weight: 300;
	src: url('#{$futura-fonts-path}futura-t-book.woff') format('woff');
}

@font-face {
	font-family: 'futura-book-std';
	font-style: normal;
	font-weight: 300;
	src: url('#{$futura-fonts-path}FuturaStd-Book.woff2') format('woff2');
}

@font-face {
	font-family: 'Futura W01 Book';
	font-style: normal;
	font-weight: 400;
	src: url('#{$futura-fonts-path}futura-book.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01 Book';
	font-style: italic;
	font-weight: 400;
	src: url('#{$futura-fonts-path}futura-book-oblique.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01 Medium';
	font-style: normal;
	font-weight: 500;
	src: url('#{$futura-fonts-path}futura-medium.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01 Medium';
	font-style: italic;
	font-weight: 500;
	src: url('#{$futura-fonts-path}futura-medium-oblique.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01 Bold';
	font-style: normal;
	font-weight: 700;
	src: url('#{$futura-fonts-path}futura-bold.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01 Bold';
	font-style: italic;
	font-weight: 700;
	src: url('#{$futura-fonts-path}futura-bold-oblique.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01';
	font-style: normal;
	font-weight: 300;
	src: url('#{$futura-fonts-path}futura-book.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01';
	font-style: italic;
	font-weight: 300;
	src: url('#{$futura-fonts-path}futura-book-oblique.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01';
	font-style: normal;
	font-weight: 400;
	src: url('#{$futura-fonts-path}futura-book.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01';
	font-style: italic;
	font-weight: 400;
	src: url('#{$futura-fonts-path}futura-book-oblique.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01';
	font-style: normal;
	font-weight: 500;
	src: url('#{$futura-fonts-path}futura-medium.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01';
	font-style: italic;
	font-weight: 500;
	src: url('#{$futura-fonts-path}futura-medium-oblique.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01';
	font-style: normal;
	font-weight: 700;
	src: url('#{$futura-fonts-path}futura-bold.woff') format('woff');
}

@font-face {
	font-family: 'Futura W01';
	font-style: italic;
	font-weight: 700;
	src: url('#{$futura-fonts-path}futura-bold-oblique.woff') format('woff');
}
