.mat-mdc-radio-button {
  margin-right: 32px;
  &.mat-accent {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: $qiagen-color-blue;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: $qiagen-color-blue;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  &.mat-radio-disabled {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    &.mat-radio-checked .mat-radio-outer-circle,
    .mat-radio-outer-circle {
      border-color: $qiagen-color-grey;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-ripple .mat-ripple-element,
    .mat-radio-inner-circle {
      background-color: $qiagen-color-grey;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-label-content {
      color: $qiagen-color-grey;
    }
  }

  .mat-ripple-element {
    background-color: $qiagen-color-light-grey;
  }
}

.qiagen-uic {
  --mdc-radio-selected-focus-icon-color: #{$qiagen-color-blue};
  --mdc-radio-selected-hover-icon-color: #{$qiagen-color-blue};
  --mdc-radio-selected-icon-color: #{$qiagen-color-blue};
  --mdc-radio-selected-pressed-icon-color: #{$qiagen-color-blue};
  --mat-radio-ripple-color: #{$qiagen-color-text-grey};
  --mat-radio-checked-ripple-color: #{$qiagen-color-blue};
  --mat-radio-disabled-label-color: #{$qiagen-color-mid-grey};
}
