@use "@angular/material" as mat;
@use "sass:map";
@import "./uic.variables.scss";
@import "./layout-grid/layout-grid.scss";
@import "material-icons/iconfont/material-icons.scss";
@import "./fonts/futura/futura.scss";
@import "./fonts/glyphicons/glyphicons.scss";

//REGEX NA KOLORY : (?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^\)]*\)

$qiagen-app-primary: mat.m2-define-palette($qiagen-blue);
$qiagen-app-accent: mat.m2-define-palette($qiagen-red);
$qiagen-app-theme: map-merge(
  mat.m2-define-light-theme($qiagen-app-primary, $qiagen-app-accent),
  (
    foreground: $qiagen-foreground
  )
);

@include mat.all-component-typographies($qiagen-typography);
@include mat.core();
@include mat.all-component-themes($qiagen-app-theme);
@mixin qiagen-theme {
  @include mat.all-component-themes($qiagen-app-theme);
}

@import "./styles/button.scss";
@import "./styles/typography.scss";
@import "./styles/forms.scss";
@import "./styles/radio.scss";
@import "./styles/switch.scss";
@import "./styles/checkbox.scss";
@import "./styles/dialog.scss";
@import "./styles/nav-list.scss";
@import "./styles/card.scss";
@import "./styles/tooltip.scss";
@import "./styles/bottom-sheet.scss";
@import "./styles/tabs.scss";
@import "./styles/misc.scss";

.back-to-button {
  margin-bottom: 20px;
  button {
    &.mat-mdc-button,
    &.mat-mdc-icon-button {
      color: $qiagen-color-text-grey;
    }
  }
}
.qiagen-uic {
  color: $qiagen-color-text-grey;
}
