@import 'glyphicons.mixins.scss';
@import 'glyphicons.variables.scss';
$glyphicons-fonts-path: '' !default;
@font-face {
	font-family: $glyphicons-family;
	font-style: normal;
	font-weight: normal;
	src: url('#{$glyphicons-fonts-path}glyphicons.woff') format('woff');
}

[class^='icon-'],
[class*=' icon-'] {
	@include font-glyphicons($glyphicons-family);
}

@include list-glyphicons($glyphicons);
