mat-form-field {
	&.mat-mdc-form-field:hover:not(.mat-focused):not(.mat-form-field-disabled):not(.mat-form-field-invalid) {
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-label {
			color: $qiagen-color-text-grey;
		}
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-outline-thick,
		.mat-form-field-outline {
			color: $qiagen-color-text-grey;
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-outline-gap,
			.mat-form-field-outline-start,
			.mat-form-field-outline-end {
				border-width: 1px !important;
			}
		}
	}
	&.mat-form-field-appearance-outline {
		/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
		.mat-select-arrow-wrapper {
			transform: translateY(0%);
		}
		&.mat-form-field-disabled {
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-outline {
				color: $qiagen-color-mid-grey;
			}
		}
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-outline-thick {
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-outline-gap,
			.mat-form-field-outline-start,
			.mat-form-field-outline-end {
				border-width: 1px !important;
			}
		}
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-outline {
			color: $qiagen-color-grey;
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-outline-start {
				width: 8px !important;
				border-radius: 10px 0 0 10px !important;
			}
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-outline-end {
				border-radius: 0 10px 10px 0 !important;
			}
		}
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-label {
			color: $qiagen-color-grey;
			.mat-form-field-disabled & {
				color: $qiagen-color-mid-grey;
			}
		}
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		&.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
			transform: translateY(-1.35em) scale(0.75);
			width: 133.33333%;
		}
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-infix {
			padding: 0.65em 0 !important;
			top: -0.3em;
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-label-wrapper {
				top: -0.92em;
				padding-top: 0.92em;
			}
		}
	}
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
	-webkit-text-fill-color: $qiagen-color-mid-grey;
	opacity: 1;
}

.qiagen-uic{
  @include mat.form-field-density(-3);
  $subscript-font: map.get($qiagen-typography-extended, "caption-small");

  --mat-form-field-subscript-text-size: #{map.get($subscript-font, "font-size")};
  --mat-select-trigger-text-size: 16px;
  --mat-option-label-text-size: 16px;

  --mdc-shape-small: 12px;
  mat-form-field {
    margin-top: 6px;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: white;
    border-radius: 12px;
  }

  $body-2-font: map.get($qiagen-typography, "body-2");
  --mat-form-field-container-height: 48px;
  --mat-form-field-container-vertical-padding: 9px;
  --mat-form-field-container-text-font: #{map.get($body-2-font, "font-family")};
  --mat-form-field-container-text-line-height: #{map.get($body-2-font, "line-height")};
  --mat-form-field-container-text-size: #{map.get($body-2-font, "font-size")};
  --mat-form-field-container-text-tracking: #{map.get($body-2-font, "letter-spacing")};
  --mat-form-field-container-text-weight: #{map.get($body-2-font, "font-weight")};
  --mdc-outlined-text-field-container-shape: 12px;
  --mdc-outlined-text-field-label-text-size: #{map.get($body-2-font, "font-size")};

  --mdc-outlined-text-field-focus-outline-width: 1px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-outline-color: #{$qiagen-color-grey};
  --mdc-outlined-text-field-label-text-color: #{$qiagen-color-grey};
  --mdc-outlined-text-field-hover-outline-color: #{$qiagen-color-text-grey};
  --mdc-outlined-text-field-hover-label-text-color: #{$qiagen-color-text-grey};

  --mdc-outlined-text-field-disabled-outline-color: #{$qiagen-color-mid-grey};
  --mdc-outlined-text-field-disabled-label-text-color: #{$qiagen-color-mid-grey};
  --mdc-outlined-text-field-disabled-hover-outline-color: #{$qiagen-color-mid-grey};
  --mdc-outlined-text-field-disabled-hover-label-text-color: #{$qiagen-color-mid-grey};
  --mdc-outlined-text-field-disabled-input-text-color: #{$qiagen-color-mid-grey};

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-29.75px)
      scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 23px;
  }

  --mat-select-enabled-arrow-color:#{$qiagen-color-grey};
}