@use "@angular/material" as mat;
.mat-mdc-dialog-content {
  @include mat.m2-typography-level($qiagen-typography, body-1);
}
.mat-mdc-dialog-actions {
  position: relative;
  button[qiasphereHelp] {
    position: absolute;
    left: 0;
    top: 8px;
  }
}
.qiagen-uic {
  --mdc-dialog-supporting-text-color: #{$qiagen-color-text-grey};
  --mdc-dialog-subhead-color: #{$qiagen-color-text-grey};
}
