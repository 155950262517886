@use "@angular/material" as mat;

$qiagen-typography: mat.m2-define-typography-config(
  $font-family: "Futura W01",
  $headline-1: mat.m2-define-typography-level(3.75rem, 5.125rem, 400, "Futura W01 Book", -0.031rem),
  $headline-2: mat.m2-define-typography-level(3rem, 4rem, 500, "Futura W01 Medium", 0),
  $headline-3: mat.m2-define-typography-level(2.5rem, 3.3125rem, 500, "Futura W01 Medium", 0.016rem),
  $headline-4: mat.m2-define-typography-level(2.125rem, 3rem, 500, "Futura W01 Medium", 0.016rem),
  $headline-5: mat.m2-define-typography-level(1.75rem, 2.125, 500, "Futura W01 Medium", 0),
  $headline-6: mat.m2-define-typography-level(1.25rem, 1.875rem, 400, "Futura W01 Medium", 0.009rem),
  $body-1: mat.m2-define-typography-level(1.25rem, 1.875rem, 400, "Futura W01 Book", 0.009rem),
  $subtitle-1: mat.m2-define-typography-level(1.125rem, 1.75rem, 500, "Futura W01 Medium", 0.006rem),
  $body-2: mat.m2-define-typography-level(1.125rem, 1.75rem, 400, "Futura W01 Book", 0.031rem),
  $subtitle-2: mat.m2-define-typography-level(1rem, 1.5625rem, 400, "Futura W01 Book", 0.016rem),
  $button: mat.m2-define-typography-level(1rem, 1.3125rem, 500, "Futura W01 Bold", 0.078rem),
  $caption: mat.m2-define-typography-level(1rem, 1.5rem, 400, "Futura W01 Book", 0.025rem)
);
$qiagen-typography-extended: (
  overline: mat.m2-define-typography-level(0.75rem, 1.375rem, 500, "Futura W01 Medium", 0.1rem),
  caption-2: mat.m2-define-typography-level(1rem, 1.375rem, 500, "Futura W01 Medium", 0.025rem),
  caption-small: mat.m2-define-typography-level(0.8125rem, 1.25rem, 400, "Futura W01 Book", 0.031rem),
  h5-5: mat.m2-define-typography-level(1.5rem, 2rem, 500, "Futura W01 Medium", 0)
);

// Colors

$qiagen-color-white: #ffffff;
$qiagen-color-black: #000;
$qiagen-color-black-70: rgba($qiagen-color-black, 0.7);
$qiagen-color-dark-blue: #1b3067;
$qiagen-color-active-blue: #1043aa;
$qiagen-color-blue: #1562ad;
$qiagen-color-mid-blue: #87a6d5;
$qiagen-color-light-blue: #cce0f3;
$qiagen-color-extra-light-blue: #e5eff9;
$qiagen-color-super-light-blue: #f2f7fc;
$qiagen-color-light-blue-50: rgba($qiagen-color-light-blue, 0.5);

$qiagen-color-text-grey: #47443f;
$qiagen-color-grey: #8a8886;
$qiagen-color-mid-grey: #bcbcbb;
$qiagen-color-light-grey: #f3f3f3;
$qiagen-color-light-grey-50: rgba($qiagen-color-light-grey, 0.5);
$qiagen-color-extra-light-grey: #f9f9f9;

$qiagen-color-red: #e0003c;
$qiagen-color-mid-red: #f299b1;
$qiagen-color-light-red: #fbeae5;
$qiagen-color-extra-light-red: #fcece7;

$qiagen-color-green: #007045;
$qiagen-color-mid-green: #66a98f;
$qiagen-color-light-green: #e3fcef;

$qiagen-color-yellow: #ffab00;
$qiagen-color-mid-yellow: #ffcc66;
$qiagen-color-light-yellow: #fffae6;

$qiagen-blue: (
  50: #e3ecf5,
  100: #b9d0e6,
  200: #8ab1d6,
  300: #5b91c6,
  400: #387ab9,
  500: #1562ad,
  600: #125aa6,
  700: #0f509c,
  800: #0c4693,
  900: #063483,
  A100: #b1c9ff,
  A200: #7ea6ff,
  A400: #4b83ff,
  A700: #3171ff,
  contrast: (
    50: $qiagen-color-text-grey,
    100: $qiagen-color-text-grey,
    200: $qiagen-color-text-grey,
    300: $qiagen-color-text-grey,
    400: $qiagen-color-extra-light-grey,
    500: $qiagen-color-extra-light-grey,
    600: $qiagen-color-extra-light-grey,
    700: $qiagen-color-extra-light-grey,
    800: $qiagen-color-extra-light-grey,
    900: $qiagen-color-extra-light-grey,
    A100: $qiagen-color-text-grey,
    A200: $qiagen-color-text-grey,
    A400: $qiagen-color-text-grey,
    A700: $qiagen-color-extra-light-grey
  )
);
$qiagen-red: (
  50: #fbe0e8,
  100: #f6b3c5,
  200: #f0809e,
  300: #e94d77,
  400: #e52659,
  500: #e0003c,
  600: #dc0036,
  700: #d8002e,
  800: #d30027,
  900: #cb001a,
  A100: #fff3f4,
  A200: #ffc0c5,
  A400: #ff8d95,
  A700: #ff747e,
  contrast: (
    50: $qiagen-color-text-grey,
    100: $qiagen-color-text-grey,
    200: $qiagen-color-text-grey,
    300: $qiagen-color-text-grey,
    400: $qiagen-color-extra-light-grey,
    500: $qiagen-color-extra-light-grey,
    600: $qiagen-color-extra-light-grey,
    700: $qiagen-color-extra-light-grey,
    800: $qiagen-color-extra-light-grey,
    900: $qiagen-color-extra-light-grey,
    A100: $qiagen-color-text-grey,
    A200: $qiagen-color-text-grey,
    A400: $qiagen-color-text-grey,
    A700: $qiagen-color-text-grey
  )
);
$qiagen-yellow: (
  50: #fff5e0,
  100: #ffe6b3,
  200: #ffd580,
  300: #ffc44d,
  400: #ffb826,
  500: #ffab00,
  600: #ffa400,
  700: #ff9a00,
  800: #ff9100,
  900: #ff8000,
  A100: $qiagen-color-extra-light-grey,
  A200: #fff8f2,
  A400: #ffdcbf,
  A700: #ffcea6,
  contrast: (
    50: $qiagen-color-text-grey,
    100: $qiagen-color-text-grey,
    200: $qiagen-color-text-grey,
    300: $qiagen-color-text-grey,
    400: $qiagen-color-text-grey,
    500: $qiagen-color-text-grey,
    600: $qiagen-color-text-grey,
    700: $qiagen-color-text-grey,
    800: $qiagen-color-text-grey,
    900: $qiagen-color-text-grey,
    A100: $qiagen-color-text-grey,
    A200: $qiagen-color-text-grey,
    A400: $qiagen-color-text-grey,
    A700: $qiagen-color-text-grey
  )
);
$qiagen-green: (
  50: #e0eee9,
  100: #b3d4c7,
  200: #80b8a2,
  300: #4d9b7d,
  400: #268561,
  500: #007045,
  600: #00683e,
  700: #005d36,
  800: #00532e,
  900: #00411f,
  A100: #76ffa8,
  A200: #43ff88,
  A400: #10ff68,
  A700: #00f65a,
  contrast: (
    50: $qiagen-color-text-grey,
    100: $qiagen-color-text-grey,
    200: $qiagen-color-text-grey,
    300: $qiagen-color-text-grey,
    400: $qiagen-color-extra-light-grey,
    500: $qiagen-color-extra-light-grey,
    600: $qiagen-color-extra-light-grey,
    700: $qiagen-color-extra-light-grey,
    800: $qiagen-color-extra-light-grey,
    900: $qiagen-color-extra-light-grey,
    A100: $qiagen-color-text-grey,
    A200: $qiagen-color-text-grey,
    A400: $qiagen-color-text-grey,
    A700: $qiagen-color-text-grey
  )
);
$qiagen-foreground: (
  base: $qiagen-color-text-grey,
  divider: $qiagen-color-light-grey,
  dividers: $qiagen-color-light-grey,
  disabled: $qiagen-color-mid-grey,
  disabled-button: $qiagen-color-mid-grey,
  disabled-text: $qiagen-color-mid-grey,
  hint-text: $qiagen-color-grey,
  secondary-text: $qiagen-color-grey,
  icon: $qiagen-color-grey,
  icons: $qiagen-color-grey,
  text: $qiagen-color-text-grey,
  slider-off: $qiagen-color-text-grey,
  slider-off-active: $qiagen-color-text-grey
);
// $qiagen-background: (
//   status-bar: map_get(mat.$grey-palette, 300),
//   app-bar:    map_get(mat.$grey-palette, 100),
//   background: map_get(mat.$grey-palette, 50),
//   hover:      rgba(200, 200, 200, 0.04), // TODO(kara): check style with Material Design UX
//   card:       white,
//   dialog:     white,
//   disabled-button: rgba(black, 0.12),
//   raised-button: white,
//   focused-button: rgba(black, 0.06),
//   selected-button: map_get(mat.$grey-palette, 300),
//   selected-disabled-button: map_get(mat.$grey-palette, 400),
//   disabled-button-toggle: map_get(mat.$grey-palette, 200),
//   unselected-chip: map_get(mat.$grey-palette, 300),
// );
@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//TODO: REMOVE BALOW AFTER DASHBOARD MIGRATION TO ANGULAR MATERIAL
// Font family
$font-futura-book: "Futura W01 Book", Arial, Helvetica, sans-serif;
$font-futura-medium: "Futura W01 Medium", Arial, Helvetica, sans-serif;
$font-futura-bold: "Futura W01 Bold", Arial, Helvetica, sans-serif;
$font-futura: "Futura W01", Arial, Helvetica, sans-serif;
$font-family-default: $font-futura;
$font-futura: $font-futura;

// Font size
$font-size: 14px;
$font-size-large: 16px;
$font-size-small: 12px;

// Font weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;
$font-weight-default: $font-weight-normal;

$qui-background-color: #f9f9f9;
