.mat-mdc-card-content > :last-child:not(.mat-mdc-card-footer),
.mat-card > :last-child:not(.mat-card-footer) {
  margin-top: 0px;
  @supports (display: grid) {
    margin-top: initial;
  }
}
.qiagen-uic {
  --mdc-outlined-card-outline-color: #{$qiagen-color-light-grey};
  --mdc-outlined-card-outline-width: 1px;
  --mdc-outlined-card-container-elevation: var(--mat-app-elevation-shadow-level-1, none);
  --mdc-outlined-card-container-shape: 10px;
}
