@mixin font-glyphicons($font-family) {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: $font-family !important;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
}

@mixin list-glyphicons($glyphicons-map) {
	@each $glyph, $code in $glyphicons-map {
		.icon-#{$glyph} {
			&::before {
				content: '#{$code}';
			}
		}
	}
}

@mixin add-glyphicon($font-family, $glyphicons-map, $glyphicon) {
	@include font-glyphicons($font-family);
	content: map-get($glyphicons-map, $glyphicon);
}
