@use "@angular/material" as mat;
@use "sass:map";

.qiagen-uic {
  .mat-mdc-tab-header {
    $subtitle-1-font: map.get($qiagen-typography, "subtitle-1");
    --mat-tab-header-label-text-tracking: 0.06rem;

    --mat-tab-header-label-text-font: #{map.get($subtitle-1-font, "font-family")};
    --mat-tab-header-label-text-line-height: #{map.get($subtitle-1-font, "line-height")};
    --mat-tab-header-label-text-size: #{map.get($subtitle-1-font, "font-size")};
    --mat-tab-header-label-text-tracking: #{map.get($subtitle-1-font, "letter-spacing")};
    --mat-tab-header-label-text-weight: #{map.get($subtitle-1-font, "font-weight")};

    --mat-tab-header-inactive-label-text-color: #{$qiagen-color-grey};
    --mat-tab-header-inactive-focus-label-text-color: #{$qiagen-color-grey};
    --mat-tab-header-inactive-hover-label-text-color: #{$qiagen-color-grey};
  }
}
