@import '@material/layout-grid/mdc-layout-grid';

[class^='mdc-layout-grid__cell--'] {
	margin: 12px;
	@supports (display: grid) {
		margin: initial;
	}
}
.mdc-layout-grid__inner {
	margin: 0px;
	@supports (display: grid) {
		margin: initial;
	}
}
