/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-frame {
  border: 2px solid $qiagen-color-text-grey;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checked.mat-primary {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-background {
    background-color: transparent;
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-checkmark {
      transform: scale(0.85);
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-frame {
    border: 2px solid $qiagen-color-blue;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-checkmark-path {
    stroke: $qiagen-color-blue !important;
    stroke-width: 3px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  &.mat-checkbox-disabled {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-frame {
      border: 2px solid $qiagen-color-mid-grey;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-checkmark-path {
      stroke: $qiagen-color-mid-grey !important;
      stroke-width: 3px !important;
    }
  }
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background-color: transparent;
  border-color: $qiagen-color-blue !important;
  // &::after {
  // 	color: $qiagen-color-blue;
  // }
}
.qiagen-uic {
  .mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-disabled-selected-icon-color: rgba(71, 68, 63, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(71, 68, 63, 0.38);

    --mdc-checkbox-selected-checkmark-color: #{$qiagen-color-blue};
    --mdc-checkbox-selected-focus-icon-color: #{$qiagen-color-extra-light-grey};
    --mdc-checkbox-selected-hover-icon-color: #{$qiagen-color-extra-light-grey};
    --mdc-checkbox-selected-icon-color: #{$qiagen-color-blue};
    --mdc-checkbox-selected-focus-state-layer-color: #{$qiagen-color-extra-light-grey};
    --mdc-checkbox-selected-hover-state-layer-color: #{$qiagen-color-extra-light-grey};
    --mdc-checkbox-selected-pressed-state-layer-color: #{$qiagen-color-extra-light-grey};

    --mdc-checkbox-unselected-focus-icon-color: #{$qiagen-color-text-grey};
    --mdc-checkbox-unselected-hover-icon-color: #{$qiagen-color-text-grey};
    --mdc-checkbox-unselected-icon-color: #{$qiagen-color-text-grey};
    --mdc-checkbox-unselected-focus-state-layer-color: #{$qiagen-color-text-grey};
    --mdc-checkbox-unselected-hover-state-layer-color: #{$qiagen-color-text-grey};
    --mdc-checkbox-unselected-pressed-state-layer-color: #{$qiagen-color-text-grey};
    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
      border-color: var(--mdc-checkbox-selected-icon-color);
      background-color: white;
    }
    .mdc-checkbox__background {
      background-color: white;
    }
    .mat-internal-form-field {
      align-items: flex-start;
      & > label {
        margin-top: 8px;
      }
    }
  }
}
