@use '@angular/material' as mat;
.mat-overline {
	@include mat.m2-typography-level($qiagen-typography-extended, overline);
	text-transform: uppercase;
}
.mat-caption-2 {
	@include mat.m2-typography-level($qiagen-typography-extended, caption-2);
}
.mat-caption-small {
	@include mat.m2-typography-level($qiagen-typography-extended, caption-small);
}
.mat-h5-5 {
	@include mat.m2-typography-level($qiagen-typography-extended, h5-5);
}
