$glyphicons-family: 'glyphicons';
$glyphicons: (
	dtool: '\e900',
	hour-service: '\e901',
	applications: '\e902',
	archive: '\e903',
	arrow-back: '\e904',
	arrow-down: '\e905',
	arrow-forward: '\e906',
	arrow-up: '\e907',
	attachment: '\e908',
	augmented: '\e909',
	auto-transfer: '\e90a',
	backup: '\e90b',
	barcode: '\e90c',
	biorobot: '\e90d',
	blogsite: '\e90e',
	brightness-less: '\e90f',
	brightness-more: '\e910',
	calendar: '\e911',
	call-back: '\e912',
	callout-caution: '\e913',
	callout-help: '\e914',
	callout-info: '\e915',
	change-database: '\e916',
	checksum-validator: '\e917',
	close: '\e918',
	contact: '\e919',
	cvs-exchange: '\e91a',
	database: '\e91b',
	discount: '\e91c',
	discount-25: '\e91d',
	disk: '\e91e',
	dislike: '\e91f',
	document: '\e920',
	edit: '\e921',
	facebook: '\e922',
	file-export: '\e923',
	file-import: '\e924',
	file-transfer: '\e925',
	fill-status: '\e926',
	filter: '\e927',
	flickr: '\e928',
	fluids: '\e929',
	folder: '\e92a',
	folder-favorite: '\e92b',
	formula: '\e92b',
	geneglobe: '\e92d',
	generead: '\e92e',
	genreader18: '\e92f',
	global: '\e930',
	goto-end: '\e931',
	goto-start: '\e932',
	gr-link: '\e933',
	home: '\e934',
	image: '\e935',
	imagegalery: '\e936',
	industry: '\e937',
	instagram: '\e938',
	kit-tube: '\e939',
	labspace: '\e93a',
	legal: '\e93b',
	library: '\e93c',
	like: '\e93d',
	link-download: '\e93e',
	linkedin: '\e93f',
	link-upload: '\e940',
	link-weblink: '\e941',
	liquid-handling: '\e942',
	locked: '\e943',
	login: '\e944',
	logout: '\e945',
	mail: '\e946',
	manager: '\e947',
	meltcurves: '\e948',
	menue: '\e949',
	minus: '\e94a',
	money: '\e94b',
	monitor: '\e94c',
	monitor-performance: '\e94d',
	network: '\e94e',
	ok: '\e94f',
	organize: '\e950',
	password: '\e951',
	pause: '\e952',
	pdf: '\e953',
	person-man: '\e954',
	person-woman: '\e955',
	pin: '\e956',
	play: '\e957',
	plus: '\e958',
	position-calibration: '\e959',
	position-calibration2: '\e95a',
	presentation: '\e95b',
	print: '\e95c',
	process: '\e95d',
	process-wizard: '\e95e',
	protocol-timer: '\e96f',
	pump-calibration: '\e960',
	qiacube: '\e961',
	qiagility: '\e962',
	qiastock: '\e963',
	qiasymphony: '\e964',
	qiaxpert: '\e965',
	qr-code: '\e966',
	reload: '\e967',
	reports: '\e968',
	restore: '\e969',
	rotorgene: '\e96a',
	rss: '\e96b',
	sample-multiplex: '\e96c',
	samples: '\e96d',
	sample-single: '\e96e',
	save: '\e96f',
	scientific-poster: '\e970',
	screen-clust: '\e971',
	script-editor: '\e972',
	search: '\e973',
	search-document: '\e974',
	send-kart: '\e975',
	set-bookmark: '\e976',
	set-favorit: '\e977',
	settings: '\e978',
	settings2: '\e979',
	share: '\e97a',
	shop: '\e97b',
	signer: '\e97c',
	sitemap: '\e97d',
	slideshare: '\e97e',
	software-archive: '\e97f',
	sound: '\e980',
	special-offer: '\e981',
	specifications: '\e982',
	status-approved: '\e983',
	status-not-approved: '\e984',
	status-rejected: '\e985',
	status-running: '\e986',
	stop: '\e987',
	support: '\e988',
	switch-off: '\e989',
	switch-on: '\e98a',
	target: '\e98b',
	temperature: '\e98c',
	template-favorite: '\e98d',
	time: '\e98e',
	timeinterval: '\e98f',
	timeline: '\e990',
	tools: '\e991',
	touch: '\e992',
	trash: '\e993',
	twitter: '\e994',
	unload: '\e995',
	upgrade-software: '\e996',
	usb: '\e997',
	user: '\e998',
	volume-less: '\e999',
	volume-more: '\e99a',
	wavelength-calibration: '\e99b',
	webinar: '\e99c',
	website: '\e99d',
	window-maximize: '\e99e',
	window-minimize: '\e99f',
	workflow-sample: '\e9a0',
	youtube: '\e9a1',
	order: '\e9a2',
	arrow-down2: '\e9a3',
	arrow-back2: '\e9a4',
	arrow-forward2: '\e9a5',
	arrow-up2: '\e9a6',
	check-mark: '\e9a7',
	close2: '\e9a8',
	user-new: '\e9a9',
	quick-order: '\e9aa',
	liquid-biopsy: '\e9ab',
	bio-informatics: '\e9ac',
	elearning: '\e9ad',
	epigenetics: '\e9ae',
	gene-expression: '\e9af',
	genotyping: '\e9b0',
	geonomics: '\e9b1',
	genomics: '\e9b1',
	human-id-applications: '\e9b2',
	infectious-disease-testing: '\e9b3',
	knowledgebase: '\e9b4',
	microbiome: '\e9b5',
	partnering-for-precision-diagnostics: '\e9b6',
	plant-research: '\e9b7',
	sexual-and-reproductive-health: '\e9b8',
	single-cell-analysis: '\e9b9',
	tb-management: '\e9ba',
	transplant-management: '\e9bb',
	whitepaper: '\e9bc',
	webinars: '\e9bd',
	videos: '\e9be',
	oncology: '\e9bf',
	handbook: '\e9c0',
	genomics-service: '\e9d8',
	molecular-bio-methods: '\e9e8',
	technology: '\e9d2',
	virtual-demo: '\e9c1',
	technologies: '\e9c2',
	drag-flat: '\e9c2',
);
