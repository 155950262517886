.mat-mdc-button-base {
  height: 48px;
}
.mat-mdc-button-base {
  border-radius: 10px !important;
  &.mat-mdc-button,
  &.mat-mdc-icon-button {
    transition: background-color 0.2s cubic-bezier(0.35, 0, 0.25, 1);
    transition-property: background-color;
    transition-duration: 0.2s, 0.2s;
    transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1), cubic-bezier(0.35, 0, 0.25, 1);
    transition-delay: 0s, 0s;
    &:not([disabled]):hover {
      background-color: $qiagen-color-super-light-blue;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-focus-overlay {
      display: none;
    }
  }
  &.mat-mdc-unelevated-button {
    transition: background-color 0.2s cubic-bezier(0.35, 0, 0.25, 1);
    transition-property: background-color;
    transition-duration: 0.2s, 0.2s;
    transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1), cubic-bezier(0.35, 0, 0.25, 1);
    transition-delay: 0s, 0s;
    &:not([disabled]):hover {
      &[color="primary"] {
        background-color: $qiagen-color-dark-blue;
      }
    }
  }
}
.mat-mdc-button,
.mat-mdc-unelevated-button {
  text-transform: uppercase;
}
// .mat-mdc-outlined-button {
//   /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
//   &:not(.mat-button-disabled) {
//     border-color: $qiagen-color-grey;
//     &.mat-primary {
//       border-color: $qiagen-color-blue;
//     }
//   }
// }
.mat-button-toggle-group-appearance-standard {
  .mat-button-toggle {
    @include mat.m2-typography-level($qiagen-typography, headline-6);
  }
}
.qiagen-uic {
  --mdc-text-button-container-height: 48px;
  --mdc-filled-button-container-height: 48px;
  --mdc-outlined-button-container-height: 48px;
  --mdc-protected-button-container-height: 48px;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-transform: uppercase;
  --mdc-text-button-label-text-transform: uppercase;
  --mdc-outlined-button-label-text-transform: uppercase;
  --mdc-protected-button-label-text-transform: uppercase;

  .mat-mdc-outlined-button.mat-primary {
    --mdc-outlined-button-disabled-label-text-color: #{$qiagen-color-mid-grey};
    --mdc-outlined-button-disabled-outline-color: #{$qiagen-color-mid-grey};
    --mdc-outlined-button-outline-label-text-color: #{$qiagen-color-blue};
    --mdc-outlined-button-outline-color: #{$qiagen-color-blue};
  }
  .mat-mdc-button > .mat-icon,
  .mat-mdc-unelevated-button > .mat-icon,
  .mat-mdc-raised-button > .mat-icon,
  .mat-mdc-outlined-button > .mat-icon {
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-size: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  //toggle-button
  --mat-standard-button-toggle-shape: 10px;
  --mat-standard-button-toggle-selected-state-background-color: #{$qiagen-color-light-blue};
  --mat-standard-button-toggle-selected-state-text-color: #{$qiagen-color-blue};
}
