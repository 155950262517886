@import 'libs/uic/src/lib/uic.styles.scss';
@import 'libs/uic/src/lib/uic.variables.scss';

/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version. */
mat-tooltip-component > .mat-tooltip-multiline {
  white-space: pre-line;
  max-width: 300px;
}

.mat-icon.favorite {
	color: $qiagen-color-blue;
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	&.cdk-program-focused .mat-button-focus-overlay {
		opacity: 0 !important;
	}
}

*,
::after,
::before {
	box-sizing: border-box;
}

body {
	background-color: $qiagen-color-extra-light-grey;
	margin: 0;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}