mat-form-field.mat-mdc-form-field {
	width: 100%;

	&.hidden {
		height: 32px;
		margin: 0 13px;
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-flex {
			display: none;
		}
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-underline {
			display: none;
		}
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-subscript-wrapper {
			margin-top: 0;
			top: 0;
		}
	}
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-no-pagination {
	/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
	.mat-tab-header-pagination {
		display: none !important;
	}
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
	color: $qiagen-color-mid-grey;
}
