.qiagen-uic {
  --mdc-switch-unselected-track-color: #{$qiagen-color-grey};
  --mdc-switch-unselected-hover-track-color: #{$qiagen-color-grey};
  --mdc-switch-unselected-focus-track-color: #{$qiagen-color-grey};
  --mdc-switch-unselected-pressed-track-color: #{$qiagen-color-grey};

  --mdc-switch-unselected-handle-color: #{$qiagen-color-extra-light-grey};
  --mdc-switch-unselected-focus-handle-color: #{$qiagen-color-extra-light-grey};
  --mdc-switch-unselected-hover-handle-color: #{$qiagen-color-extra-light-grey};
  --mdc-switch-unselected-pressed-handle-color: #{$qiagen-color-extra-light-grey};

  --mdc-switch-disabled-selected-handle-color: #{$qiagen-color-blue};
  --mdc-switch-disabled-selected-track-color: #{$qiagen-color-blue};
  --mdc-switch-disabled-unselected-handle-color: #{$qiagen-color-light-grey};
  --mdc-switch-disabled-unselected-track-color: #{$qiagen-color-text-grey};
}
