.mat-mdc-nav-list {
  --mdc-list-list-item-label-text-color: #{$qiagen-color-grey};
  --mdc-list-list-item-hover-label-text-color: #{$qiagen-color-grey};
  --mdc-list-list-item-hover-state-layer-color: #{$qiagen-color-super-light-blue};
  --mdc-list-list-item-hover-state-layer-opacity: 0.1;

  --mdc-list-list-item-focus-label-text-color: #{$qiagen-color-blue};
  --mdc-list-list-item-focus-state-layer-color: #{$qiagen-color-super-light-blue};
  --mdc-list-list-item-focus-state-layer-opacity: 0.1;

}
